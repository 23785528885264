import JSZip from "jszip";
import {FileResponse} from "../client";

export class FileDownloadService {
    downloadFile(fileResponse: FileResponse) {
        const base64Content = `data:${fileResponse.mimeType};base64,${fileResponse.content}`;
        const fileName = `${fileResponse.fileName}${fileResponse.extension}`;

        fetch(base64Content)
            .then(res => res.blob())
            .then(blob => this.saveBlob(blob, fileName));
    }

    downloadFilesZipped(fileResponses: FileResponse[], zipPrefix: string) {
        if (fileResponses.length == 1) {
            this.downloadFile(fileResponses[0]);
            return;
        }
        const zip = new JSZip();

        //@formatter:off
        fileResponses.forEach(fileResponse => {
           zip.file(
               `${fileResponse.fileName}${fileResponse.extension}`,
               fileResponse.content,
               {base64: true}
           );
        });
        //@formatter:on

        zip.generateAsync({type: "blob"})
            .then(blob => this.saveBlob(blob, `${zipPrefix}-${this.getFormattedDateTime()}.zip`));
    }

    protected saveBlob(blob: Blob, fileName: string) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private getFormattedDateTime() {
        const date = new Date();
        return (
            [
                [
                    date.getFullYear(),
                    this.padTo2Digits(date.getMonth() + 1), // month indexing starts from '0'
                    this.padTo2Digits(date.getDate())
                ].join(''),
                [
                    this.padTo2Digits(date.getHours()),
                    this.padTo2Digits(date.getMinutes()),
                    this.padTo2Digits(date.getSeconds())
                ].join('')
            ].join('-')
        );
    }

    private padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }
}
